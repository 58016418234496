@import url("https://fonts.googleapis.com/css?family=Poppins");
.App {
  background-color: white;

}
::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #888 #f4f4f4;
  /* thumb and track colors */
}

input[type="password"]::-ms-reveal {
    display: none; /* Hide the reveal icon in Edge -> edge extra password eye icon fix */
}

.custom-input::placeholder {
  color: rgb(156, 154, 154);  /* Change placeholder text color to grey */
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 470px;
  height: 500px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.2px;
}

.button-submit {
  /* width: 360px;
  height: 55px; */
  left: 577px;
  top: 682px;
  background: #1c614e;
  border-radius: 5px;
  color: #ffffff;
}

.react-tel-input .form-control {
  padding: 9px 12px 9px 48px !important;
  border-radius: 5px;
  line-height: 22px !important;
  height: auto !important;
  width: 100% !important;
}

.css-heg063-MuiTabs-flexContainer{
  display: block!important;
}

.css-1r6lffb-MuiTabs-root {
 min-height: 0px!important;
}

.css-1uvydh2{
  padding: 10px 12px !important;
}

.jobSearchMenuBtn{
  height: 55px;
  width: 200px;
  background-color: hsla(163, 55%, 25%, 0.184);
  border-radius: 10px;
  position: relative;
  right: 50px;
  cursor: pointer;
}
.jobSearchMenuBtn img{
  height: 30px;
  margin:2px auto;
  position: relative;
  left: 72px;
}
.jobSearchMenuBtn span{
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 13px;
  left: 20px;
  color: black;
}
.AppliedJobsBtn{
  height: 55px;
  width: 200px;
  background-color: #1c614d2f;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.AppliedJobsBtn svg{
  position: absolute;
  right: 48%;
  top: 5px;
  color: rgb(28, 97, 77);
}
.AppliedJobsBtn span{
  position: absolute;
  bottom: 0px;
  font-size: 13px;
  left: 20px;
  color: black;
}

.ErrorPage {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the items horizontally */
  justify-content: center; /* Center the items vertically if needed */
  height: 100vh; /* Full height of the viewport, adjust as needed */
  text-align: center; /* Center text inside the elements */
  background-image: url('./Images/Group839.png');
}
.ErrorPage img{
  background-color: rgb(255, 255, 255);
  padding: 50px;
  border-radius: 20%;
}
.ErrorPage h1,
.ErrorPage a {
  color: white;
  margin-bottom: 10px; /* Adds some space between elements */
  text-decoration: none;
}
.ErrorPage a {
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 20px;
}

/* Add this CSS to your global stylesheet or within a <style> tag */
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color:rgb(74, 74, 74) !important; /* Remove border color */
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color:rgb(74, 74, 74) !important; /* Remove border color on hover */
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:rgb(74, 74, 74) !important; /* Remove border color when focused */
}





