.form-file-upload {
  font-family: "Times New Roman", Times, serif;
  font-size: 12;
  color: darkslategrey;
}
.jobseekerProfile
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 12px;
}

