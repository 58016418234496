/* Editor Structure */
.ql-container {
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
}

.ql-toolbar {
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
}

.ql-editor {
  min-height: 150px;
  padding: 10px;
}

/* Alignment */
.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

/* Color */
.ql-color-red {
  color: red;
}

.ql-color-blue {
  color: blue;
}

.ql-color-green {
  color: green;
}

.ql-background-red {
  background-color: red;
}

.ql-background-blue {
  background-color: blue;
}

.ql-background-green {
  background-color: green;
}

/* Text Styling */
.ql-bold {
  font-weight: bold;
}

.ql-italic {
  font-style: italic;
}

.ql-underline {
  text-decoration: underline;
}

.ql-strike {
  text-decoration: line-through;
}

/* Lists */
.ql-list-ordered {
  list-style-type: decimal;
}

.ql-list-bullet {
  list-style-type: disc;
}

/* Indentation */
.ql-indent-1 {
  margin-left: 10px;
}

.ql-indent-2 {
  margin-left: 20px;
}

.ql-indent-3 {
  margin-left: 30px;
}

/* Headers */
.ql-header-1 {
  font-size: 24px;
  font-weight: bold;
}

.ql-header-2 {
  font-size: 20px;
  font-weight: bold;
}

/* Block Quotes and Code */
.ql-blockquote {
  border-left: 4px solid #ccc;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #666;
}

.ql-code {
  font-family: "Courier New", Courier, monospace;
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 3px;
}

.ql-code-block {
  font-family: "Courier New", Courier, monospace;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 3px;
}

/* Links and Media */
.ql-link {
  color: blue;
  text-decoration: underline;
}

.ql-video {
  display: block;
  max-width: 100%;
  margin: 10px 0;
}

.ql-image {
  display: block;
  max-width: 100%;
  margin: 10px 0;
}

/* Direction */
.ql-direction-rtl {
  direction: rtl;
  text-align: right;
}

.ql-direction-ltr {
  direction: ltr;
  text-align: left;
}

