.container-2FA {
  display: block;
  position: relative;
  top: 20px;
  background: #fff;
  border-radius: 50px;
  width: 60vw;
  min-height: 400px;
  margin: auto;
  padding: 10px 30px 50px 30px;
}
.container-2FA h1 {
  color: #000;
  font-size: 2em;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
}
.form__group_2FA {
  margin: 10px 0 0;
}
.form__pincode {
  text-align: center;
  display: block;
  width: 100%;
  margin: 10px auto 20px;
  padding: 0;
}
.form__pincode label {
  display: block;
  text-align: center;
  margin: 10px 0;
}
.form__pincode input {
  float: left;
  width: 15%;
  height: 50px;
  line-height: 48px;
  text-align: center;
  font-size: 2em;
  color: #181819;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, .3);
  border-radius: 2px 2px 0 0;
  transition: background-color .3s, color .3s, opacity .3s;
  cursor: text;
  user-select: none;
  margin: 0;
  margin-top: 10px;
  margin-right: 2%;
  padding: 0;
  transition: 1s all;
}
.wrongValue input{
  float: left;
  width: 15%;
  height: 50px;
  line-height: 48px;
  text-align: center;
  font-size: 2em;
  color: #181819;
  border: 0;
  border-bottom: 3px solid rgb(255, 0, 0);
  border-radius: 2px 2px 0 0;
  transition: background-color .3s, color .3s, opacity .3s;
  cursor: text;
  user-select: none;
  margin: 0;
  margin-top: 10px;
  margin-right: 2%;
  padding: 0;
}
.rightValue input{
  float: left;
  width: 15%;
  height: 50px;
  line-height: 48px;
  text-align: center;
  font-size: 2em;
  color: #181819;
  border: 0;
  border-bottom: 3px solid rgb(18, 158, 0);
  border-radius: 2px 2px 0 0;
  transition: background-color .3s, color .3s, opacity .3s;
  cursor: text;
  user-select: none;
  margin: 0;
  margin-top: 10px;
  margin-right: 2%;
  padding: 0;
}
.form__pincode input:focus {
  outline: 0;
  box-shadow: none;
  /* border-color: #1486f0; */
  animation: border-pulsate 1.5s infinite;
  -webkit-tap-highlight-color: transparent;
}
.form__pincode input:last-child {
  margin-right: 0;
}
.form__pincode input[disabled] {
  background: #eee;
  opacity: 1;
}
.form__buttons {
  position: relative;
  top: 30px;
  text-align: center;
  margin:auto;
  padding: 10px 0 0;
}
.button {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  margin: 0;
  padding: 0 20px;
}
.button--primary {
  /* background-color: #4776e6; */
  color: #fff;
  line-height: 48px;
  border: 1px solid transparent;
  border-radius: 50px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: all .2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
  -webkit-touch-callout: none;
}
.button--primary:focus,
.button--primary:hover {
  /* background-color: #1753de; */
  color: #fff;
}
.button--primary:active {
  /* background-color: #4776e6; */
  color: #fff;
}
.button--primary[disabled] {
  background-color: #ccc; /* Change to your desired color */
  color: #666; /* Change to your desired color */
  cursor: not-allowed;
}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .7;
}

/* Firefox 19+ */
::-moz-placeholder {
  color: inherit;
 
}