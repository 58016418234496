#jobseeker_navbar a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 15px;
    cursor: pointer;
}

.navLink {
    display: flex;
    align-items: center;
}

#jobseeker_navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}