.icon-box {
  width: 40px;
  height: 30px;
  border-radius: 2px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* For Recommended, Applied and Similar job view - 17-06-24 - vis */

.card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-container {
  /* width: 300px; */
  /* margin: 10px 0; */
}

.card {
  /* border: 1px solid #ccc; */
  /* padding: 20px; */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card:hover {
  background-color: #f0f0f0;
}

.card-container.selected .card {
  background-color: #d0f0d0;
  /* border-color: #4caf50; */
}

/* For ribbon - 18-06-24 - vis*/


.ribbon-1-right {
  position: absolute;
  right: 20px;
  top: 40px;
  /* background: #1c614e;
  color: white;
  box-shadow: 0 0 0 999px #1c614e;
  clip-path: inset(0 -100%);
  inset: 0 0 auto auto;
  transform-origin: 0 0;
  transform: translate(29.3%) rotate(45deg); */
  height: 50px;
}
.ribbon-1-right span {
  position: relative;
  top: 10px;
}

.ribbon-stamp{
  font-family: 'Courier';
	font-weight: 700;
  position: absolute;
  top: 40%;
  right: 10%;
  transform: rotate(-10deg);
  letter-spacing: 20px;  
}

.ribbon-stamp span {
  border-radius: 1rem;
  color: #1C614E;
  border: 0px;
	-webkit-mask-position: 2rem 3rem;
  font-size: 2rem;
}


@media screen and (max-width: 1300px) {
  .ribbon-1-right {
    height: 30px;
  }
  .ribbon-1-right span {
    top: 0px;
  }
}


/* For job search post login scroll */
.scrollable-container-Jobsearchpostlogin {
  /* border:2px solid red; */
  position: relative;
  /* height: 100%; Adjust the height as needed */
  overflow-y: auto;
}


/* For stamp v1________________________________________________________________________ */

.stamp-module{
  font-family: 'Courier';
	font-weight: 700;
  position: absolute;
  top: 45%;
  transform: rotate(-35deg);
  letter-spacing: 20px;   
}

.stamp-list {
  font-family: 'Courier';
	font-weight: 700;
  position: absolute;
  top: 45%;
  transform: rotate(-15deg);
  letter-spacing: 20px;   
}

.is-applied {
  color: #1C614E;
  border: 0px;
	-webkit-mask-position: 2rem 3rem;
  font-size: 2rem;  
}

.is-shortlisted {
  color: #0073bf;
  border: 0px;
	-webkit-mask-position: 2rem 3rem;
  font-size: 2rem;  
}
