.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #a9a9a9;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.scrollBar {
  overflow-y: scroll;
  scrollbar-color: rgb(163, 163, 163) rgb(224, 224, 224);
  scrollbar-width: thin;
}
.scrollBar::-webkit-scrollbar {
  width: 1em;
}
.scrollBar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
