table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: none;
  padding: 8px;
  text-align: left;
}

.css-i44wyl{
  width: 100%!important;
}