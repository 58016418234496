@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
html {
  margin: 0;
  padding: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiTypography-root,
.MuiInputBase-root,
.MuiButton-root,
.MuiChip-root,
.MuiSelect-select {
  font-family: "Poppins" !important;
}
::placeholder {
  color: #000000d9;
}
label {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
} */
.MuiSelect-select {
  padding: 10px 12px !important;
}
.css-1pqm26d-MuiAvatar-img {
  /* object-fit: contain !important; */
  background: #1c614e1c;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 40vh !important;
}
.MuiAutocomplete-paper {
  max-height: 30vh;
  overflow: hidden !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 12px !important;
}

.css-1xhypcz-MuiStack-root {
  padding-top: 0px !important ;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 30px;
  font-size: 14px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 12px !important;
}
