section {
  height: 100vh;
}
#header a.active {
  border-bottom: 5px solid #ba8c04;
  /* color: #ba8c04; */
}
#header a {
  color: #1c614e;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  margin: 0px 10px;
  cursor: pointer;
}
.photo {
  height: 70px;
  width: 70px;
}
.display-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block {
  display: block;
}
.landingPage {
  padding: 0;
}
.landingPage-img1 {
  height: 570px;
  margin-top: 1rem;
}
.zIndex {
  z-index: 1 !important;
}
.inputField {
  position: relative;
  margin: -105px auto 10px;
  z-index: 2 !important;
  padding: 6px 20px 6px 10px;
  display: flex;
  align-items: center;
  height: 80;
  border-radius: 13px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.06);
  background: #fff;
}

.css-yz9k0d-MuiInputBase-input::placeholder {
  color:rgb(188, 188, 188) !important;
  opacity: 1 !important;
}

.joboffers-img {
  width: 100%;
}
.advertisement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.jobCompanies-img1 {
  position: relative;
  z-index: 1;
  margin: 25px 0px 0px 100px;
  height: 550px;
}

.jobCompanies-img2 {
  position: absolute;
  z-index: 2;
  top: -40px;
  left: 325px;
}

.workFromHome-img {
  width: 100%;
  height: 100%;
}
.circle1 {
  width: 225px;
  height: 225px;
  border-radius: 100%;
  background: rgba(178, 207, 207, 0.15);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 15%;
}
.circle2 {
  width: 250px;
  height: 250px;
  border-radius: 100%;
  background: rgba(178, 207, 207, 0.15);
  position: absolute;
  z-index: 2;
  top: 35%;
  left: 25%;
}
.subscribe-input {
  position: relative;
  /* height: 100px; */
  width: 350px;
}

.subscribe-input input {
  width: 100%;
  padding: 10px;
  border-radius: 25px;
  border: none;
  outline: none;
}

.subscribe-input button {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff;
  background: #1c614e;
  border-radius: 25px;
  border: none;
  outline: none;
  padding: 5px 10px;
}
.about-img1 {
  position: relative;
  z-index: 1;
  top: -70px;
}
.about-img2 {
  position: absolute;
  z-index: 2;
  bottom: -100px;
  right: 0;
  /* top: -2px; */
  /* left: 170px; */
}
.contact a {
  text-decoration: none;
  color: #000;
}

.MuiInputBase-root {
  padding: 0 !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 10px 12px !important;
}
.MuiInputAdornment-root {
  padding-right: 12px;
}

video {
  width: 650px;
}

.custom-chip:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.css-1aquho2-MuiTabs-indicator{
display: none!important;
}

@media only screen and (max-width: 800px) {
  .landingPage {
    padding: 10px;
  }

  .landingPage-img1 {
    height: 300px !important;
  }

  .advertisement {
    display: block;
    justify-content: center;
  }

  .subscribe-input {
    position: relative;
    /* height: 100px; */
    width: 90%;
  }
  .flex {
    display: block;
    text-align: center;
  }
  .circle1,
  .circle2,
  .joboffers-img,
  .jobCompanies-img2,
  .about-img1,
  .about-img2,
  .workFromHome-img {
    display: none;
  }
  section {
    height: auto;
  }
  video {
    width: 485px !important;
  }
}

@media screen and (max-width: 620px) {
  .display-flex {
    display: block;
  }
  .landingPage-img1 {
    display: none;
  }
  .landingPage {
    padding-top: 25px;
  }
  video {
    width: 350px !important;
  }
}

@media screen and (max-width: 1200px) {
  .landingPage-img1 {
    height: 450px;
  }

  .jobCompanies-img1 {
    height: 435px;
  }

  .loginImg {
    display: none;
  }
}

@media only screen and (max-width: 1335px) {
  .jobCompanies-img2 {
    left: 260px;
    top: -5px;
  }
}

@media screen and (max-width: 900px) {
  .mikeImg {
    height: 300px;
    width: auto;
    z-index: 5;
  }
}
