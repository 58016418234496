.social-google-btn {
  background-color: #dd4b39;
  color: white;
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  display: inline-block;
  opacity: 0.8;
  transition:0.1s ease-in-out
}
.social-facebook-btn {
  background-color: #3b5998;
  color: white;
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  display: inline-block;
  opacity: 0.8;
  transition:0.1s ease-in-out
}

.social-google-btn:hover {
  opacity: 1;
}
.social-facebook-btn:hover {
  opacity: 1;
}


/* PopUP */

.overlayOpen{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlayClose{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 80%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .content {
  height:70vh;
  overflow: auto;
}
