/* CustomModal.css */

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.701);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .custom-modal {
    background-image: url("../../../Images/Group839.png");
    padding: 20px;
    width: 80vw;
    height: 80vh;
    border-radius: 10px;
    z-index: 999;
  }
  
  .custom-modal-content {
    position: relative;
  }
  
  .custom-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 50px;
    cursor: pointer;
  }
  